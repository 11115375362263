import { ElLoading } from 'element-plus';
import { messageBoxAlert } from '../feedback';

type RequestTimeoutSpiner = null | { close: () => void };
type Request = {
  url: string;
  time: number;
};

export default class RequestTimeout {
  _value = false;

  static _spiner: RequestTimeoutSpiner = null;

  request: Request | null = null;

  static requests: Request[] = [];

  static get spiner() {
    return !!this._spiner;
  }

  static set spiner(payload: boolean) {
    if (payload && !this._spiner) {
      this._spiner = ElLoading.service({
        background: 'transparent',
      });
      return;
    }

    if (!payload && !RequestTimeout.requests.length) {
      this._spiner?.close();
      this._spiner = null;
    }
  }

  constructor(private url: string, seconds = 20) {
    this.addTimeout(url, seconds);
  }

  get value() {
    return this._value;
  }

  finish = () => {
    this._value = true;
    RequestTimeout.requests = RequestTimeout.requests.filter(
      ({ time }) => time !== this.request?.time,
    );
    this.request = null;
    RequestTimeout.spiner = false;
  };

  addTimeout = (url: string, seconds: number) => {
    this.initSpiner(url);

    setTimeout(() => {
      if (!this.value) {
        messageBoxAlert(
          'Превышено время выполнения запроса',
          'Проверьте интернет-соединение и попробуйте снова, либо обратитесь в тех.поддержку.',
        );
        throw new Error(`Превышено время запроса к ${this.url}`);
      }
    }, seconds * 1000);
  };

  initSpiner = (url: string) => {
    this.request = { url, time: Math.random() };
    RequestTimeout.spiner = true;
    RequestTimeout.requests.push(this.request);
  };
}
